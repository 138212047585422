<div id="sidebar" style="display: none;">
    <div id="mySidenav" class="sidenav">
        <a href="javascript:void(0)" class="closebtn" (click)="closeSidebar()">&times;</a>
        <ul id="sidenavList">
            <ng-container *ngFor="let menu of menuItems">
                <ng-container *ngIf="menu.is_enabled">
                    <li *ngIf="!menu.is_modal">
                        <!-- <a *ngIf="menu.name == 'auditorium'; else elseBlock" href="https://us06web.zoom.us/j/88640538614?pwd=ZmVuaFZxL2xZUVQ2WXE3RmE3VEdIQT09" target="_blank" (click)="stepUpAnalytics1('auditorium')">
                            <img src="{{menu.icon}}" alt="">
                            {{menu.name | titlecase}}
                        </a> -->
                        <!-- <ng-template #elseBlock> -->
                        <a routerLink="/{{menu.path}}" routerLinkActive="activeLink" (click)="stepUpAnalytics1(menu.path)">
                            <img src="{{menu.icon}}" alt="">
                            {{menu.name | titlecase}}
                        </a>
                    <!-- </ng-template> -->
                    </li>
                    <li *ngIf="menu.is_modal">
                        <a (click)="openModal(menu.data_target)">
                            <img src="{{menu.icon}}" alt="">
                            {{menu.name | titlecase}}
                        </a>
                    </li>
                </ng-container>
            </ng-container>
        </ul>
    </div>
    <div id="hamburger" (click)="openSidebar()" >&#9776;</div>
</div>
<!-- *ngIf="!url.url.includes('auditorium/full')" -->
<div class="footer-nav" style="display: none;">
    <ul class="footer-nav1">
        <ng-container *ngFor="let menu of menuItems">
            <ng-container *ngIf="menu.is_enabled">
                <li *ngIf="!menu.is_modal">
                    <!-- <a *ngIf="menu.name == 'auditorium'; else elseBlock" href="https://us06web.zoom.us/j/88640538614?pwd=ZmVuaFZxL2xZUVQ2WXE3RmE3VEdIQT09" target="_blank" (click)="stepUpAnalytics1('auditorium')">
                        <img src="{{menu.icon}}" alt="">
                        <div>{{menu.name | uppercase}}</div>
                    </a> -->
                    <!-- <ng-template #elseBlock> -->
                        <a [routerLink]="'/'+menu.path" routerLinkActive="activeLink" (click)="stepUpAnalytics1(menu.path)">
                            <img src="{{menu.icon}}" alt="">
                            <div>{{menu.name | uppercase}}</div>
                        </a>
                    <!-- </ng-template> -->

                </li>
                <li *ngIf="menu.is_modal">
                    <a (click)="openModal(menu.data_target)">
                        <img src="{{menu.icon}}" alt="">
                        <div>{{menu.name | uppercase}}</div>
                    </a>
                </li>
            </ng-container>
        </ng-container>
    </ul>
</div>