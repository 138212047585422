<div class="container-fluid" id="web">
    <div class="row">
        <div class="col-sm-12 col-md-12 col-xl-12 px-0 d-xs-block d-sm-block d-md-block d-xl-block">
            <img src="assets/beckton/home/web.jpg" class="login-img" alt="login image">
            <img src="assets/beckton/home/regis.jpg" class="click-img" alt="click image" routerLink="/signup">
            <div class="login-section-wrapper">
            </div>
        </div>
    </div>
</div>


<div class="container-fluid" id="mob">
    <div class="row">
        <div class="col-sm-12 col-md-12 col-xl-12 px-0 d-xs-block d-sm-block d-md-block d-xl-block">
            <img src="assets/beckton/home/home.jpg" class="login-img" alt="login image">
            <img src="assets/beckton/home/lorerClickhere-8.png" class="click-img" alt="click image" routerLink="/signup">
            <div class="login-section-wrapper">
            </div>
        </div>
    </div>
</div>
