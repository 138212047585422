import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface GeolocationCoordinates {
  latitude: number;
  longitude: number;
  altitude?: number | null;
  accuracy: number;
  altitudeAccuracy?: number | null;
  heading?: number | null;
  speed?: number | null;
}

export interface GeolocationPosition {
  coords: GeolocationCoordinates;
  timestamp: number;
}

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {
  private positions: { latitude: number; longitude: number }[] = [];

  constructor() {}

  getCurrentPosition(): Observable<GeolocationPosition> {
    return new Observable(observer => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => observer.next(position as GeolocationPosition),
          error => observer.error(error),
          { enableHighAccuracy: true }
        );
      } else {
        observer.error(new Error('Geolocation is not supported by this browser.'));
      }
    });
  }

  watchPosition(): Observable<GeolocationPosition> {
    return new Observable(observer => {
      if (navigator.geolocation) {
        const watchId = navigator.geolocation.watchPosition(
          position => observer.next(position as GeolocationPosition),
          error => observer.error(error),
          { enableHighAccuracy: true }
        );

        return () => navigator.geolocation.clearWatch(watchId);
      } else {
        observer.error(new Error('Geolocation is not supported by this browser.'));
      }
    });
  }

  calculateDistance(pos1: { latitude: number; longitude: number }, pos2: { latitude: number; longitude: number }): number {
    const R = 6371e3; // Radius of the Earth in meters
    const lat1 = pos1.latitude * Math.PI / 180;
    const lat2 = pos2.latitude * Math.PI / 180;
    const deltaLat = (pos2.latitude - pos1.latitude) * Math.PI / 180;
    const deltaLon = (pos2.longitude - pos1.longitude) * Math.PI / 180;

    const a = Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
              Math.cos(lat1) * Math.cos(lat2) *
              Math.sin(deltaLon / 2) * Math.sin(deltaLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;

    return distance; // in meters
  }
}
