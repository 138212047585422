import { Location } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ChatService } from '../services/chat.service';
import { DataService, localService } from '../services/data.service';
import Swal from 'sweetalert2';
import { AuthService } from '../services/auth.service';
import { BriefcaseModalComponent } from './briefcase-modal/briefcase-modal.component';
declare var $: any;
import swal from 'sweetalert';

// import { ToastrService } from 'ngx-toastr';


import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, AfterViewInit {

  @ViewChild(BriefcaseModalComponent) private briefcase: BriefcaseModalComponent;
  // @ViewChild(PdfmodalComponent) private pdf:PdfmodalComponent;

  constructor(private _ds: DataService, private chat: ChatService, private _ls: localService, private _auth: AuthService, public router: Router, private location: Location) { }

  textMessage = new FormControl('');
  msg;
  welcomeChatList = [];
  interval;
  check
  check2: any;
  exhibiton: any = [];
  documents: any = [];

  ngOnInit(): void {
    // this.getHelpdeskList();

    this.chat.getconnect('6059ecd30air3');
    this.chat.getMessages().subscribe((data => {
      console.log('data', data);
      let checkData = data.split('*');
      let mymail = localStorage.getItem('myemail');
      // alert(checkData)
      if (checkData[0] == mymail && checkData[1] != 'exit') {
        localStorage.setItem('user_key', checkData[1]);
        var timeleft = 10;
        var downloadTimer = setInterval(() => {
          if (timeleft <= 0) {
            clearInterval(downloadTimer);
            document.getElementById("countdown").innerHTML = "";
            document.getElementById("countdown").style.display = "none";
            this.routing();
          } else {
            document.getElementById("countdown").style.display = "block";
            document.getElementById("countdown").innerHTML = "You are going live in " + timeleft + " seconds.";

          }
          console.log(timeleft);
          timeleft -= 1;
        }, 1000);
      }

    }));

    // var arrEl = ['book', 'kick', 'rock', 'book', 'lock']
    // var books = [
    //   { title: "C++", author: "Bjarne" },
    //   { title: "Java", author: "James" },
    //   { title: "Python", author: "Guido" },
    //   { title: "Java", author: "James" },
    //   { title: "angular", author: "James" },
    //   { title: "Java", author: "kkk" },
    // ];
    // let newArray:any = [];
    // let uniqueObj:any = {};
    // books.filter((item, index)=>{
    //   uniqueObj[item.title]=index;
    //   console.log('filter',  uniqueObj);

    // });
    // for (let i in uniqueObj) {
    //   newArray.push(books[uniqueObj[i]])
    // }
    // for (const iterator of books) {

    // }
    // console.log('arr', newArray);

    // arrEl = arrEl.filter( ( item, index, inputArray )=> {
    //   return inputArray.indexOf(item) == index;
    // });
    // console.log('arr', arrEl);

  }
  routing() {
    //alert('hel');
    this.router.navigate(['/maincall']);
  }
  gotoBack() {
    this.location.back();
  }

  ngAfterViewInit() {
    this.chat.socketConnection();
    this.chat.getSocketMessages().subscribe((data: any) => {
      console.log(data);
      let check = data.split('_');
      console.log(data, 'okkk');
      let realdata = JSON.parse(localStorage.getItem('virtual'));
      if (check[0] == 'one2one' && check[1] == realdata.id ){
        // swal(check[2]+' has messaged you.', 'success')
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 10000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        
        Toast.fire({
          icon: 'success',
          title: check[2]+' has messaged you.'
        })

      }
      // let datas = JSON.parse(localStorage.getItem('virtual'));
      if (check[0] == 'start' && check[1] == 'audio') {
        // alert('hello')
        this.router.navigateByUrl('/auditorium/stage/205206');
      }

      const splitData = data.split('_');

      if (data.includes('custom_live')) {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })

        Toast.fire({
          icon: 'success',
          title: 'Auditorium will begin soon'
        });
      }
      setTimeout(() => {
        this.getId(splitData[2]);
      }, 3000);
    })
    this.chat.socketConnection();
    this.chat.getSocketMessages().subscribe((data: any) => {
      console.log(data)
      this.check2 = data.split('_');
      // alert(this.check2)
      if (this.check2[0] == 'question' && this.check2[1] == 'reply') {
        this.getHelpdeskList();
      }
      if (this.check2[0] == 'start' && this.check2[1] == 'poll' && this.check2[2] == '205002') {
        $('#poll_modal').modal('show')
      }
    });
  }

  getHelpdeskList() {
    let data = JSON.parse(localStorage.getItem('virtual'));
    this._ds.getHelpdeskList(data.id).subscribe((res: any) => {
      this.welcomeChatList = res.result;
    });
  }
  postMyQuestion(value) {
    if (value != '' && value != null) {
      let data = JSON.parse(localStorage.getItem('virtual'));
      this._ds.postHelpDeskQuestion(data.id, value).subscribe((res => {
        if (res.code == 1) {
          this.textMessage.reset();
          let arr = {
            "question": value,
            "answer": ""
          };
          this.welcomeChatList.push(arr);
        }
      }));
    }
  }

  closeModal() {
    $('#welcome_chat_modal').modal('hide');
    $("#pdf_modal").modal('hide');
      $("#agenda_modal").modal('hide');
      $("#faqs_modal").modal('hide');
      $("#win_modal").modal('hide');
      $("#quiz_modal").modal('hide');
  }

  submitPageAnalytics(item) {
    this._ls.stepUpAnalytics(item);
    this.router.navigate(['/login']);
      localStorage.clear();
  }
  exit() {
    let user_id = JSON.parse(localStorage.getItem('virtual')).id;
    let signify_userid = JSON.parse(localStorage.getItem('getdata')).id;
    const formsData = new FormData();
    formsData.append('user_id', signify_userid);
    formsData.append('flag', '0');
    const newdata = new FormData();
    newdata.append('user_id', signify_userid);
    newdata.append('status', '0');
    this._ds.handraise(formsData).subscribe(res => {
      console.log(res);
    });
    this._ds.signify_update(formsData).subscribe(res => {

    })
    this._ds.handlogout(newdata).subscribe(res => {

    });
    this._ds.logout(user_id).subscribe(res => {
      this.router.navigate(['/login']);
      localStorage.clear();
    });


    // localStorage.removeItem('virtual');
    // this.router.navigate(['/login']);
  }

  getId(id) {
    this._auth.settingItems$.subscribe(items => {
      if (items.length) {
        let path = items[0]["auditorium"].filter(x => x.id == id)[0]['path'];
        this.router.navigate(['/auditorium/' + path + '/' + id]);
      }
    });
  }
  readOutputMenu(item) {
    console.log(item);
    // if(item==='pdf'){
    //   $('#pdfmodal').modal('show');
    // }
    // this.sName = item.title;
    // if(!this.sName.toLowerCase().includes('helpdesk')){
    //   $('#pdf_modal').modal('show');
    // }
    if (item === 'briefcase') {
      this.briefcase.getBriefcaseList();
    } else {

    }
  }
}