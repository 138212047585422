<div class="modal fade" id="leaderboard_modal" tabindex="-1" aria-labelledby="leaderboard_modalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" id="ModalPlay">
      <div class="modal-content">      
        <div class="modal-body p-0">
          <div id="modal_popup">
              <div id="close_modal_popup" (click)="closeModal()">&times;</div>    
              <div class="modal-header bg-primary text-white">
                  <h5 class="modal-title" id="leaderboard_modalLabel">Leaderboard</h5>
              </div>
              <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-12 col-xl-12 p-0 mt-1">
                        <nav>
                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                              <button *ngFor="let pill of navpills" class="nav-link {{pill.class}}" id="{{pill.class}}" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" [attr.aria-selected]="pill.is_selected" (click)="selectPill(pill)">{{pill.pill | titlecase}}</button>
                            </div>
                        </nav>
                        <div class="tab-content mt-2" id="nav-tabContent">
                            <div *ngFor="let pill of navpills; let i=index;" class="tab-pane fade {{pill.pillClass}}" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                <div [ngSwitch]="i" style="height: 451px;overflow: auto;" class="m-3">
                                    <div *ngSwitchCase="0">
                                      <div id="leaderPill">
                                        <div id="SearchItem">
                                          <input type="text" placeholder="search" name="" class="form-control"id="search_item"  (keyup)="onSearchItem($event.target.value)">
                                          <i class="fa fa-search"></i>
                                        </div>
                                        <div></div>
                                        <div>
                                          <button class="btn btn-success"(click)="getLeaderboardList()"><i class="fa fa-refresh"></i></button>
                                        </div>
                                      </div>
                                      <div>                                                    
                                          <table class="table table-hover table-striped">
                                            <thead>
                                              <tr>
                                                <th scope="col">#Rank</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Points</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr *ngFor="let b of leaderboardList; let i = index">
                                                <td>{{i+1}}</td>
                                                <td>{{b.name}}</td>
                                                <td>
                                                  {{b.points}} 
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                      </div>
                                    </div>
                                    <div *ngSwitchCase="1">
                                        <div> 
                                            <h6>My Total Points: {{myPoints}}</h6>                                                   
                                            <table class="table table-hover">
                                              <thead>
                                                <tr>
                                                  <th scope="col">Activities Available for Points</th>
                                                  <th scope="col">Point Value </th>
                                                  <th scope="col">My Points </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr *ngFor="let b of userDataList">
                                                  <td>{{b.click_point}}</td>
                                                  <td>{{b.points}}</td>
                                                  <td>{{b.points}}</td>
                                                </tr>
                                              </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div *ngSwitchCase="2">
                                      <div> 
                                          <h6>My Total Points: {{myPoints}}</h6>                                                   
                                          <table class="table table-hover table-bordered">
                                            <thead>
                                              <tr>
                                                <th scope="col">Criteria</th>
                                                <th scope="col">Points </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr *ngFor="let b of criteria_data">
                                                <td>{{b.criteria | titlecase}}</td>
                                                <td>{{b.points}}</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                      </div>
                                  </div>
                                  <div *ngSwitchCase="3" style="text-align: justify;">
                                    <p>
                                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. In neque nibh, ornare et mi non, ultrices iaculis mi. Nulla nulla turpis, sagittis sit amet varius id, dignissim id purus. Aliquam at neque nibh. Praesent interdum arcu eleifend urna sagittis euismod. Nulla dictum maximus justo, non lacinia nisl tincidunt quis. Nullam eleifend quam non quam condimentum malesuada. Morbi dapibus mattis est. Suspendisse pellentesque fermentum pellentesque. Quisque dignissim justo massa, quis ornare eros faucibus sit amet. Duis eget odio ut metus ornare gravida. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Maecenas ultricies ornare tortor. Integer et gravida odio. Duis sollicitudin congue dapibus.
                                    </p>
                                    <p>
                                      Sed fringilla fringilla ex quis iaculis. Nulla commodo rutrum ligula. Etiam rhoncus lectus malesuada est dapibus ultricies. Nam metus ex, placerat ut nunc ac, ultrices feugiat erat. Quisque mi risus, efficitur fermentum venenatis sit amet, consectetur at nisl. Maecenas ac massa quis sapien efficitur commodo. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Sed porttitor tellus diam, porta suscipit sem dictum eu. Nam faucibus elit a urna viverra eleifend. Quisque molestie augue eu lectus blandit iaculis id quis sapien. Proin nec neque sapien. Etiam maximus velit sed tellus congue vulputate.
                                    </p>
                                    <p>
                                      Morbi faucibus, eros pharetra scelerisque placerat, quam nulla dignissim metus, nec molestie turpis velit lobortis metus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Proin consectetur in ex ac rutrum. Suspendisse id dictum elit, id dictum eros. Proin in libero facilisis, egestas tortor non, vulputate urna. Praesent dictum nisi scelerisque imperdiet accumsan. Quisque porttitor mauris molestie molestie facilisis. Ut vel hendrerit arcu, id luctus nulla.
                                    </p>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
              </div>
          </div>      
        </div>      
      </div>
    </div>
</div>