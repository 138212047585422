import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Eventid } from '../shared/eventid';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  token = `toujeo-${Eventid.event_id}`;
  constructor(private socket: Socket) { }
  public socketConnection() {
    this.socket.emit('bigboyget', {
      token: this.token,
    });
  }

  public getSocketMessages = ()=>{
    return Observable.create((observer)=>{
      this.socket.on(this.token, (message)=>{
        observer.next(message);
      });
    });
  }







  public getconnect(token) {
    this.socket.emit('bigboy', {
      token: token,
    });
    // console.log(token);
  }
  public getMessages = () => {
    return Observable.create((observer) => {
      this.socket.on('toujeo-294', (message) => {

        observer.next(message);
      });
    });
  }









  sendMessage(msg: string) {
    this.socket.emit('message', msg);
  }
  getMessage() {
    return this.socket.fromEvent('message').pipe(map((data:any) => data.msg));
  }
}
