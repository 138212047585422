import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { DataService, localService } from 'src/app/services/data.service';
import { BriefcaseModalComponent } from '../briefcase-modal/briefcase-modal.component';
import { MenuItems } from './menu.items';
declare var $:any;
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  menuItems = [];
  @ViewChild(BriefcaseModalComponent) private briefcase:BriefcaseModalComponent;
  @Output() outputMenu = new EventEmitter(); 
  sName: any;

  constructor(public url: Router, private _ds: DataService, private _auth: AuthService,private _ls:localService) { }

  ngOnInit(): void {
    // this._ds.getSettingSection().subscribe(res=>
    //   // console.log(res['menuItems']);
    //   this.menuItems = res['menuItems']
    // );
    // this._auth.getMessages().subscribe(res=>this.menuItems=res['menuItems']);
    this._auth.settingItems$.subscribe(items => {
      this.menuItems = items.length?items[0]["menuItems"]:items; 
    });
  }

  openSidebar() {
    document.getElementById("mySidenav").style.width = "220px";
  }

  closeSidebar() {
    document.getElementById("mySidenav").style.width = "0";
  }
  logout(){

  }
  stepUpAnalytics1(item){
    this._ls.stepUpAnalytics(`click_`+item);
  }
  openModal(item){
    
    // this.outputMenu.emit(item);
    this.sName = item;

    // $(`#${item}_modal`).modal("show");

    
      if (this.sName == "faqs") {
        // alert("faqs");
        this._ls.stepUpAnalytics("click_faqs");
        $("#faqs_modal").modal("show");
      } else {
        this._ls.stepUpAnalytics("click_agenda");

        $("#agenda_modal").modal("show");
      }
    

    // alert(`#${item}_modal`)
  // if(item=='pdf'){
  //   $('#pdf_modal').modal('show');
  // }
  }

}
