<!-- <span id="skip" *ngIf="videoPlay">
  <a class="nav-link sign" (click)="skipButton()" title="SKIP" role="button">
      SKIP</a>
</span> -->
<div class="container-fluid" >
    <div class="row">
      <div class="col-sm-12 col-md-12 col-xl-12 px-0 d-xs-block d-sm-block d-md-block d-xl-block">
        <img src="assets/event/thanks2.jpg" class="login-img" alt="login image">
        <div class="login-section-wrapper">

         
        </div>
      </div>
    </div>
  </div>
  <!-- <div [ngStyle]="{'display':videoPlay? 'block': 'none'}" class="videoContainer">
        <video id="myVideo" preload="auto" class="videoBg" (ended)="endVideo()">
            <source src="https://d3ep09c8x21fmh.cloudfront.net/fmc/Lobby View_Morning.mp4" type="video/mp4">
          </video>
  </div> -->
  <div>

  </div>
  