<!-- <div class="container-fluid" style="background-color: #ccc; height: 100vh;">
    <div class="row">
        <div class="col-lg-3"></div>
        <div class="col-lg-6">
          
        </div>
        <div class="col-lg-3"></div>
    </div>
</div>
 -->

 <div class="frames">
    <div id="frame" style="width: 100vw; height: 100vh;"></div>
   </div>