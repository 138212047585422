import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';  
import * as FileSaver from 'file-saver';  
import { Observable } from 'rxjs';
declare var $:any;
@Component({
  selector: 'app-briefcase-modal',
  templateUrl: './briefcase-modal.component.html',
  styleUrls: ['./briefcase-modal.component.scss']
})
export class BriefcaseModalComponent implements OnInit {
  briefcaseList = [];
  is_selected_all = false;
  is_selected_one = false;
  selectedItemsZip = [];
  pdfByteArrays = [];
  constructor(private _ds: DataService) { }

  ngOnInit(): void {
    // this.getBriefcaseList();
  }
  closeModal(){
    $("#briefcase_modal").modal("hide");
  }
  getBriefcaseList(){
    const user_id = JSON.parse(localStorage.getItem('virtual')).id;
    this._ds.getBriefcaseList(user_id).pipe(map((res:any)=>{
      this.briefcaseList = res.result;
    })).subscribe();
  }
  downloadPdf(url){
    let pdfUrl = url;
    let pdfName = 'sample.pdf';
    saveAs(pdfUrl, pdfName);    
  }
  selectedZip(event, indx){
    if(event.target.checked){
      this.createFile(this.briefcaseList[indx]['document']); 
      // this.selectedItemsZip[indx][event.target.id] = event.target.checked;
    } else{
      // this.selectedItemsZip.splice(indx, 1);
      this.deleteFile(this.briefcaseList[indx]['document']); 
      // this.selectedItemsZip.splice(indx, 1);

    }
  }
  selectZipAll(count){
    this.is_selected_all = !this.is_selected_all;
    for(let i=0; i<count; i++){
      this['check'+i+1] = document.getElementById('check_'+(i+1));
      if(this.is_selected_all){
        this['check'+i+1].checked = true;
        this.pdfByteArrays = []; 
        this.createFile(this.briefcaseList[i]['document']);
      } else{
        this['check'+i+1].checked = false;
        this.pdfByteArrays = []; 
        // this.deleteFile(this.briefcaseList[i]['document']);
        this.is_selected_all = false;
      }
      console.log(this['check'+i+1].checked, this['check'+i+1]);
    }
  }
  async createFile(url){
    this.selectedItemsZip.push(url);
    let response = await fetch(url);
    let data = await response.blob();
    let metadata = {
      type: 'application/pdf'
    };
    let file = new Blob([data], metadata);    
    this.pdfByteArrays.push(file);   
    console.log(this.pdfByteArrays);

  }
  async deleteFile(url){
    this.selectedItemsZip.push(url);
    let response = await fetch(url);
    let data = await response.blob();
    let metadata = {
      type: 'application/pdf'
    };
    let file = new Blob([data], metadata);  
    this.pdfByteArrays.splice(this.pdfByteArrays.indexOf(file), 1);  
    console.log(this.pdfByteArrays);

  }
 
  downloadZip() {
    var zip = new JSZip();
    zip.file("Hello.txt", "Hello World\n");
    var pdf = zip.folder("pdfs");
    this.pdfByteArrays.forEach((value,i) => {
      pdf.file((i+1)+'.pdf',value, { base64: true });
    });
    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, "list.zip");
    });
  }
  
}
