<div class="modal fade" id="briefcase_modal" tabindex="-1" aria-labelledby="briefcase_modalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" id="ModalPlay">
      <div class="modal-content">      
        <div class="modal-body p-0">
          <div id="modal_popup">
              <div id="close_modal_popup" (click)="closeModal()">&times;</div>    
              <div class="modal-header bg-primary text-white">
                  <h5 class="modal-title" id="briefcase_modalLabel">Briefcase</h5>
              </div>
              <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-12 col-xl-12 p-0">
                      <div id="popupData">
                      </div>
                      <div id="popupDocsData" >
                        <div style="height: 451px;overflow: auto;" class="m-3"> 
                          <span >
                            <button class="btn btn-success" (click)="downloadZip()">download Zip</button>
                          </span>                         
                          <table class="table table-hover">
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col"><input class="form-check-input" type="checkbox" value="" id="check_all" (change)="selectZipAll(briefcaseList.length)"></th>
                                <th scope="col">Name</th>
                                <th scope="col">View</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let b of briefcaseList; let i = index">
                                <td>{{i+1}}</td>
                                <td><input class="form-check-input" type="checkbox" value="" id="check_{{i+1}}" (change)="selectedZip($event, i)"></td>
                                <td>{{b.title}}</td>
                                <td>
                                  <a href="{{b.document}}" target="_blank">
                                    <i class="fa fa-eye" aria-hidden="true" style="font-size: 24px;"></i>
                                  </a>  
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
          </div>      
        </div>      
      </div>
    </div>
  </div>