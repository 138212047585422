<!-- 
<div [ngStyle]="{'display':portrait?'none':'block'}">
  <div id="potrait">
      <img src="assets/rotate.gif" alt="">
      <p>Switch to Portrait view</p> -->
      <!-- <div class="loader"></div> -->
  <!-- </div>
</div> -->
<!-- </div> -->
<!-- <div [ngStyle]="{'display':portrait?'block':'none'}"> -->
  <router-outlet></router-outlet>
<!-- </div> -->