import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { GeolocationService } from './services/geolocation.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderService } from './services/loader.service';
import { ReqInterceptor } from './shared/interceptor/req.interceptor';
import {SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { MaincallComponent } from './maincall/maincall.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
const data: SocketIoConfig ={ url : 'https://belive.multitvsolution.com:8030', options: {} };
// import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TimerComponent } from './timer/timer.component';
@NgModule({
  declarations: [
    AppComponent,
    MaincallComponent,
    ThankyouComponent,
    TimerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SocketIoModule.forRoot(data),
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule  
    // TooltipModule.forRoot()


  ],
  
  providers: [LoaderService,GeolocationService,
    { provide: HTTP_INTERCEPTORS, useClass: ReqInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
