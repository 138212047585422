import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Eventid } from '../shared/eventid';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  SET_JSON = environment.set_json; 
 
  BASE_URL = environment.baseUrl;
  EVENT_ID = Eventid.event_id;
  /* SET_JSON = `https://d3ep09c8x21fmh.cloudfront.net/virtual_event/event_${this.EVENT_ID}/config.json` */
  // EVENT_ID = '180';
  ACTIVE_AUDI = 'get/auditorium';
  ASK_LIVE_QUESTIONS = 'ask/live/question/post';
  constructor(private http: HttpClient) { }

  // beckton

  dickLogin(id,name){
    return this.http.get(`${this.BASE_URL}/auth/login/event_id/${this.EVENT_ID}/email/${id}/name/${name}`);
    // return this.http.get(`${this.BASE_URL}/auth/login/event_id/${this.EVENT_ID}/mobile/${mob}`);
  }

  waterDrinking(data:any): Observable<any>{
      return this.http.post(`${this.BASE_URL}/water/drinking/post/event_id/${this.EVENT_ID}`,data)
  }

  bookReading(data:any): Observable<any>{
    return this.http.post(`${this.BASE_URL}/reading/post/event_id/${this.EVENT_ID}`,data)
  }

  meditation(data:any): Observable<any>{
    return this.http.post(`${this.BASE_URL}/meditation/post/event_id/${this.EVENT_ID}`,data)
  }

  walking(data:any): Observable<any>{
    return this.http.post(`${this.BASE_URL}/walking/post/event_id/${this.EVENT_ID}`,data)
  }
  
  getSettingSection(){
    return this.http.get(this.SET_JSON);
  }
  getActiveAuditorium(id):Observable<any>{
    return this.http.get(`${this.BASE_URL}/${this.ACTIVE_AUDI}/event_id/${this.EVENT_ID}/id/${id}`);
  }
  askLiveQuestions(id, value, audi_id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8');
    let params = new HttpParams();
    params = params.set('user_id', id);
    params = params.set('question', value);
    params = params.set('event_id', this.EVENT_ID);
    params = params.set('audi_id', audi_id);
    return this.http.post(`${this.BASE_URL}/${this.ASK_LIVE_QUESTIONS}`, params);
  }
  heartbeat(params): Observable<any> {
    return this.http.post(`${this.BASE_URL}/analytics/user/heartbeat`, params);
  }
  analyticsPost(analytics: any): Observable<any>{
    // return this.http.post(`${this.BASE_URL}/analytics/user/history/add`, analytics);
    return this.http.post(`${this.BASE_URL}/post/analytics/user/history/event_id/294`, analytics);
    
  }
  pollSubmit(id, data, value): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8');
    let params = new HttpParams();
    params = params.set('poll_id', id);
    params = params.set('user_id', data);
    params = params.set('answer', value);
    return this.http.post(`${this.BASE_URL}/poll/answer/post`, params);
  }
  totalTimeSlots(ex_id,stall_id,date):Observable<any> {
    return this.http.get(`https://virtualapi.multitvsolution.com/upload_photo/calender.php?exhibition_id=${ex_id}&stall_id=${stall_id}&date=${date}
    `);
    // https://virtualapi.multitvsolution.com/upload_photo/calender.php?exhibition_id=2151&stall_id=767&date=2021-10-10
  }
  scheduleCallGet(dates):Observable<any> {
    return this.http.post(`${this.BASE_URL}/user/schedule/call`,dates);
  }
  getScheduleList(uid):Observable<any>{
    return this.http.get(`${this.BASE_URL}/user/schedule/call/list/user_id/${uid}/event_id/294`);
  }
  getPollsList(id){
    return this.http.get(`${this.BASE_URL}/get/poll/event_id/${this.EVENT_ID}/id/${id}`)
  }
  getGroupChattingList(room){
    // return this.http.get(`https://d4rwaq6vwd4nr.cloudfront.net/chatapi/v1/get/mongo/groupchat/room/${room}`);
    return this.http.get(`${this.BASE_URL}/get/mongo/groupchat/room/${room}`)
  }
  postGroupChatting(chat):Observable<any>{    
    return this.http.post(`${this.BASE_URL}/groupchat/post/event_id/${this.EVENT_ID}`, chat);
    // return this.http.post(`https://d4rwaq6vwd4nr.cloudfront.net/chatapi/v1/groupchat/post/event_id/${this.EVENT_ID}`, chat);
  }
  getAllAttendees(): Observable<any> {
    return this.http.get(`${this.BASE_URL}/get/attendees/event_id/${this.EVENT_ID}`);
  }
  enterTochatList(receiver_id, sender_id): Observable<any> {
    return this.http.get(`${this.BASE_URL}/one2one/chat/get/receiver_id/${receiver_id}/sender_id/${sender_id}`);
  }
  postOnetoOneChatting(chat):Observable<any>{
    return this.http.post(`${this.BASE_URL}/one2one/chat/post/event_id/${this.EVENT_ID}`, chat);
  }
  getHelpdeskList(user_id){
    return this.http.get(`${this.BASE_URL}/asked/question/answer/get/user_id/${user_id}/event_id/${this.EVENT_ID}`);
  }
  postHelpDeskQuestion(id,value): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8');
    let params = new HttpParams();
    params = params.set('event_id', this.EVENT_ID);
    params = params.set('user_id', id);
    params = params.set('question', value);
    return this.http.post(`${this.BASE_URL}/ask/question/post`, params);
  }
  getExhibitionFullHallsData():Observable<any>{
    return this.http.get(`${this.BASE_URL}/get/exhibition_hall/event_id/${this.EVENT_ID}`);
  }
  getExhibitionHallData(hall_id):Observable<any>{
    return this.http.get(`${this.BASE_URL}/get/exhibition_hall/event_id/${this.EVENT_ID}/hall/${hall_id}`);
  }
  getStallData(stall_id):Observable<any>{
    return this.http.get(`${this.BASE_URL}/get/exhibitions/event_id/${this.EVENT_ID}/id/${stall_id}`)
  }
  getGamezonedata(){
    return this.http.get(`${this.BASE_URL}/get/game/list/event_id/${this.EVENT_ID}`);
  }
  uploadCapturePic(pic): Observable<any>{
    // return this.http.post(`https://tbbmedialive.com/Q3PCM2020/uploadblob.php`, pic);
    return this.http.post(`https://virtualapi.multitvsolution.com/upload_photo/uploadblob.php`, pic);
  }
  submitEnquiryForm(enquiry): Observable<any>{
    return this.http.post(`${this.BASE_URL}/enquiry/form/add/event_id/${this.EVENT_ID}`, enquiry);
  }
  getQuiz(){
    return this.http.get(`${this.BASE_URL}/get/quiz/event_id/${this.EVENT_ID}`);
  }

  // getLoginDetails(){
  //   return this.http.get(`https://goapi.multitvsolution.com:7002/builderapi/v1/eventBuilder/get/token/6059ecd30air3`)
  // }

  getloginforhand(email){
    return this.http.get(`https://virtual.multitvsolution.com:7000/beliveapi/v1/auth/airtel/login/email/${email}/password/123456/token/6059ecd30air3`)
  }
  signify_update(datas){
    return this.http.post(`https://virtual.multitvsolution.com:7000/beliveapi/v1/call/airtel/update/token/6059ecd30air3`,datas);
  }
  handraise(data){
    return this.http.post(`https://virtual.multitvsolution.com:7000/beliveapi/v1/call/hand_raise/update/token/6059ecd30air3`,data);
  }
  postQA(data){
    return this.http.post(`${this.BASE_URL}/auditorium/day/update`,data);
  }
  getActiveAudi():Observable<any>{
    return this.http.get(`${this.BASE_URL}/${this.ACTIVE_AUDI}/event_id/${this.EVENT_ID}/id/205206`);
  }

  handlogout(data){
    return this.http.post(`https://virtual.multitvsolution.com:7000/beliveapi/v1/auth/airtel/logout`,data)
  }
  logout(user_id):Observable<any>{
    return this.http.get(`${this.BASE_URL}/auth/attendee/logout/user_id/${user_id}`);
  }
  signupRaise(data,name): Observable<any>{
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8');
    let params = new HttpParams();
    params = params.set('name', name);
    params = params.set('mobile', '9999999999');
    params = params.set('email', data);
    params = params.set('company', 'multitv');
    params = params.set('password', '123456');
    return this.http.post(`https://virtual.multitvsolution.com:7000/beliveapi/v1/airtel/signup/token/6059ecd30air3`,params)
  }
/* 
jsonFile(){
  return this.http.get(`https://d3ep09c8x21fmh.cloudfront.net/virtual_event/event_${this.EVENT_ID}/config.json`);
} */



  submitQuiz(quiz): Observable<any>{
    return this.http.post(`${this.BASE_URL}/quiz/answer/post`, quiz);
  }
  groupchating(room): Observable<any> {
    return this.http.get(`${this.BASE_URL}/get/mongo/groupchat/room/${room}`);
  }
  getAllExhibition(id): Observable<any> {
    return this.http.get(`${this.BASE_URL}/get/exhibition/sales/list/exhibition_id/${id}`);
  }

  postGroupchatOLD(value, name, email, room, created,colour):Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8');
    let params = new HttpParams();
    params = params.set('room_name', room);
    params = params.set('user_name', name);
    params = params.set('email', email);
    params = params.set('chat_data', value);
    params = params.set('is_approved', '1');
    params = params.set('event_id', this.EVENT_ID);
    params = params.set('created', created);
    params = params.set('color', colour);    
    // params = params.set('user_id', uid);
    // params = params.set('company','other');
    return this.http.post(`${this.BASE_URL}/groupchat/post/event_id/${this.EVENT_ID}`, params)
  }

  addToBriefcaseList(briefcase){
    return this.http.post(`${this.BASE_URL}/addto/briefcase/post/event_id/${this.EVENT_ID}`, briefcase);
  }

  getBriefcaseList(user_id){
    return this.http.get(`${this.BASE_URL}/get/briefcase/list/event_id/${this.EVENT_ID}/user_id/${user_id}`);
  }
  getLeaderBoradDataList(){
    const user_id = JSON.parse(localStorage.getItem('virtual')).id;
    return this.http.get(`${this.BASE_URL}/get/leaderboard/event_id/${this.EVENT_ID}/user_id/${user_id}`)
  }
}

@Injectable({
  providedIn: 'root'
})
export class localService{
  EVENT_ID = Eventid.event_id;
  constructor(private _ds: DataService, private router:Router){}
  getHeartbeat(id){    
    let data = JSON.parse(localStorage.getItem('virtual'));
    const formData = new FormData();
    formData.append('user_id', data.id );
    formData.append('event_id', this.EVENT_ID);
    formData.append('audi', id);
    this._ds.heartbeat(formData).subscribe(res => {
      // console.log(res);
    });
  }
  stepUpAnalytics(action) {    
    let virtual: any = JSON.parse(localStorage.getItem('virtual'));
    let yyyy: any = new Date().getFullYear();
    let dd: any = new Date().getDate();
    let mm: any = new Date().getMonth() + 1;
    let time: any = new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    if(virtual.company == ''){
      virtual.company = 'others';
    };
    const formData = new FormData();
    formData.append('event_id', virtual.event_id);
    formData.append('user_id', virtual.id);
    formData.append('name', virtual.name);
    formData.append('email', virtual.email);
    formData.append('company', virtual.company);
    formData.append('designation', virtual.designation);
    formData.append('action', action);
    formData.append('leaderboard', '1');
    formData.append('created', yyyy + '-' + mm + '-' + dd + ' ' + time);
    this._ds.analyticsPost(formData).subscribe(res => {
      if(action.toLowerCase().includes('logout')){
        if(res.code == 1){
          this.router.navigate(['/login']);
        }
      }
    });
  }
}
