<span id="signout" style="display: none;">
    <a id="funBtn" class="submitBtn" (click)="submitPageAnalytics('click_Logout');" data-bs-toggle="tooltip"
        role="button">
        Logout
    </a>
</span>
<div class="livemsg" id="countdown" style="display: none;">

</div>
<span id="goBack"
    [ngStyle]="{'display': (router.url.includes('walking') || router.url.includes('hydration') || router.url.includes('meditation') || router.url.includes('gallery') || router.url.includes('reading'))?'block':'none'}">
    <a id="funBtn" class="submitBtn" (click)="gotoBack()" title="Go Back" data-bs-toggle="tooltip" role="button">
        Back
    </a>
</span>
<router-outlet></router-outlet>
<app-menu (outputMenu)="readOutputMenu($event)"></app-menu>
<app-briefcase-modal></app-briefcase-modal>
<app-leaderboard-modal></app-leaderboard-modal>
<!-- <app-welcome-chat></app-welcome-chat> -->


<div class="modal fade" id="welcome_chat_modal" tabindex="-1" aria-labelledby="welcome_chat_modalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-body p-0">
                <div id="modal_popup">
                    <div id="close_modal_popup" (click)="closeModal()">&times;</div>
                    <div class="modal-header bg-primary text-white">
                        <h5 class="modal-title" id="welcome_chat_modalLabel">Help Desk</h5>
                    </div>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-12 col-xl-12 p-0">
                                <div class="chat_box">
                                    <ul class="chat_message" id="chat_messaged">
                                        <li class="chat_messageList" *ngFor="let qa of welcomeChatList">
                                            <div class="img_avatar"> <img src="assets/icons/profile_icon.png" alt="">
                                            </div>
                                            <p>
                                                <span class="person">
                                                    <a href="javascript:void(0);"
                                                        class="checkProfile">{{qa.question}}</a>
                                                </span>
                                            </p>
                                            <!-- <div class="img_avatar"> <img src="assets/icons/helpdesk_icon.png" alt=""></div> -->
                                            <div class="text_message">
                                                <img src="assets/icons/helpdesk_icon.png" *ngIf="qa.answer" alt=""
                                                    height="30px" width="30px"> &nbsp; &nbsp;{{qa.answer}}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="message_input m-3">
                                    <textarea name="message" autocomplete="off" placeholder="Enter your question"
                                        [formControl]="textMessage" maxlength="200" type="text" id="query"></textarea>
                                </div>
                                <div class="m-3">
                                    <button style="color: white;" type="submit" class="btn submitBtn"
                                        (click)="postMyQuestion(textMessage.value)">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="agenda_modal" tabindex="-1" aria-labelledby="agenda_modalLabel" aria-hidden="true">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-body p-0">
                <div id="modal_popup">
                    <div id="close_modal_popup" (click)="closeModal()">&times;</div>
                    <div class="modal-header bg-primary text-white">
                        <h5 class="modal-title" id="pdf_modalLabel">Agenda</h5>
                        <!-- {{rName | titlecase}} -->
                    </div>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-12 col-xl-12 p-0" style="text-align: center;">
                                <div>
                                    
                                    <embed src="assets/event/agenda.jpg"
                                        frameborder="0" width="100%">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="faqs_modal" tabindex="-1" aria-labelledby="faqs_modalLabel" aria-hidden="true">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-body p-0">
                <div id="modal_popup">
                    <div id="close_modal_popup" (click)="closeModal()">&times;</div>
                    <div class="modal-header bg-primary text-white">
                        <h5 class="modal-title" id="pdf_modalLabel">FAQs</h5>
                        <!-- {{rName | titlecase}} -->
                    </div>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-12 col-xl-12 p-0" style="text-align: center;">
                                <div>
                                    <embed src="assets/event/faq.jpg" frameborder="0"
                                        width="100%">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>