import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './@main/layout.component';
import { MaincallComponent } from './maincall/maincall.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { TimerComponent } from './timer/timer.component';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  { path:'', redirectTo:'welcome', pathMatch:'prefix'},
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: 'signup', loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule) },
  // { path: 'maincall', component:MaincallComponent},
  { path: 'thankyou' , component:ThankyouComponent },
  { path: 'welcome' , component:TimerComponent },
  {
    path: '',
    component: LayoutComponent,
    children: [
        { path: '', loadChildren: ()=>import('./@main/layout.module').then(m=>m.LayoutModule),canActivate: [AuthGuard]}
      ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
