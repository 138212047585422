import { HttpClient } from '@angular/common/http';
import { Component, OnInit, HostListener } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { delay } from 'rxjs/operators';
import { DataService } from './services/data.service';
import { LoaderService } from './services/loader.service';
declare var $:any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit{
  isLoaded: boolean = false;
  route;
  portrait = false;


  constructor(private _loading: LoaderService, private http: HttpClient, private router: Router,private _fd: DataService){
    this.route = this.router;
    this.router.events.subscribe((e : RouterEvent) => {
      this.navigationInterceptor(e);
    })
  }

  ngOnInit(){
    // this._fd.getLoginDetails().subscribe((res: any) => {
    //   if (res.code === 1) {
    //     localStorage.setItem('login_data', JSON.stringify(res.result));
    //   }else{
    //     window.location.reload()
    //   }
    // });

    this.listenToLoading();
    $(document).ready(()=> {
      $("body").tooltip({ selector: '[data-bs-toggle=tooltip]'});
    });

    if (window.innerHeight > window.innerWidth) {
      this.portrait = true;
    }

  }

  listenToLoading(): void {
    this._loading.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.isLoaded = loading;
      });
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.isLoaded = true;
    }
    if (event instanceof NavigationEnd) {
      this.isLoaded = false;
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.isLoaded = false;
    }
    if (event instanceof NavigationError) {
      this.isLoaded = false;
    }
  }
  @HostListener('window:resize', ['$event']) onResize(event) {
    if (window.innerHeight > window.innerWidth) {
      this.portrait = true;
    } else{
      this.portrait = false;
    }
  }
}
// {
        //     "class":"p2",
        //     "title":"helpdesk", 
        //     "top": 64, 
        //     "left": 48
        // },